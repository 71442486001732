import { useStaticQuery, graphql, Link } from 'gatsby'
import type { GetCollectionNatalQuery } from '@generated/graphql'
import { useMobile } from 'src/hooks/useMobile'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTheme } from 'src/components/contexts/ThemeContext'
import { Lazy, Navigation } from 'swiper'
import Image from 'src/components/ui/Image/Image'
import Section from 'src/components/sections/Section'

import './slick-slide.scss'

const SlickSlide = () => {
  const data = useStaticQuery<GetCollectionNatalQuery>(graphql`
    query GetCollectionNatal {
      allCmsCollectionPage {
        nodes {
          id
          sections {
            data
            id
          }
        }
      }
    }
  `)

  const { screenWidth } = useMobile()
  const { theme, themes } = useTheme()

  const { categories, shelfs } = themes.filter((idx) => idx.key === theme)[0]
    .styles

  const categoriesHome = data?.allCmsCollectionPage?.nodes.find(
    (item) => item.id === 'c1292dd7-2646-50dd-8df5-bf36f7de7841'
  )

  const categoriesHomeCheck: CategoriesHomeCheckType | undefined =
    categoriesHome?.sections.find((item) => item.id === '1669201925277')

  type CategoriesHomeCheckType = {
    data: {
      images: Array<{
        descriptionTitle: string
        imageCategories: string
        imageCategoriesUrl: string
        imageTitle: string
      }>
    }
    id: string | null
  }

  return (
    <Section className="slick-slide-section pictos-sports pictos-sports-natal layout__content layout__section">
      <Swiper
        className="slick-slide-swiper"
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
        breakpoints={{
          1244: {
            slidesPerView: 10,
          },
          1024: {
            slidesPerView: 8,
          },
          1100: {
            slidesPerView: 8,
          },
          768: {
            slidesPerView: 6,
          },
          425: {
            slidesPerView: 4,
          },
          320: {
            slidesPerView: 3,
          },
        }}
        lazy={{
          loadPrevNext: true,
          loadPrevNextAmount: 1,
        }}
        modules={[Navigation, Lazy]}
      >
        <div
          className={`swiper-button-prev !flex ${shelfs.buttonsBackground} ${shelfs.buttonsIcon}`}
        />
        <div
          className={`swiper-button-next !flex ${shelfs.buttonsBackground} ${shelfs.buttonsIcon}`}
        />
        {categoriesHomeCheck?.data?.images.map((item, index: number) => {
          return (
            <SwiperSlide key={index}>
              <Link
                key={index}
                to={item.imageCategoriesUrl}
                className={`slick-slide-item ${categories.pictosStroke} ${categories.pictosTopBackground}`}
              >
                <div className="overflow-hidden">
                  <Image
                    alt={item.imageTitle}
                    src={item.imageCategories}
                    width={screenWidth < 375 ? 60 : 80}
                    height={screenWidth < 375 ? 60 : 80}
                    loading={index < 3 ? 'eager' : 'lazy'}
                    preload={index < 3}
                    sizes="(max-width: 766px) 60px, 100vw"
                    className={index >= 3 ? 'swiper-lazy' : ''}
                  />
                </div>

                <p
                  className={`${categories.pictosBottomText} ${categories.pictosBottomBackground}`}
                >
                  {item.descriptionTitle}
                </p>
              </Link>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Section>
  )
}

export default SlickSlide
